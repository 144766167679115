<template>
  <BaseCard
    title="datos del albaran"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <DeliveryNotesForm
      ref="delivery-notes-form"
      v-model="deliveryNotes"
    />
  </BaseCard>
</template>
<script>
import ApiRestService from '@/api/base-api'
import OrdersApi from '@/api/orders-api'
import PurchaseInvoicesApi from '@/api/purchase-invoices-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import DeliveryNotesForm from '@/components/delivery-notes/form/DeliveryNotesForm.vue'

export default {
  components: { DeliveryNotesForm, BaseCard },
  data() {
    return {
      loading: false,
      deliveryNotes: {},
    }
  },
  computed: {
    orderId() {
      return this.$route.query['order-id'] || null
    },
    purchaseInvoiceId() {
      return this.$route.query['purchase-invoice-id'] || null
    },
  },
  mounted() {
    this.loadOrder()
    this.loadPurchaseInvoice()
  },
  methods: {
    async loadOrder() {
      if (!this.orderId) {
        return
      }

      this.loading = true
      try {
        const response = await OrdersApi.get(this.orderId)
        this.deliveryNotes = { order: response.data }
      } finally {
        this.loading = false
      }
    },
    async loadPurchaseInvoice() {
      if (!this.purchaseInvoiceId) {
        return
      }

      this.loading = true
      try {
        const response = await PurchaseInvoicesApi.get(this.purchaseInvoiceId)
        this.deliveryNotes = { purchase_invoice: response.data.data }
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const valid = await this.$refs['delivery-notes-form'].validateForm()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        const { documentation } = this.$refs['delivery-notes-form'].getDocumentationData()
        const response = await ApiRestService.create(
          { ...this.deliveryNotes, documentation },
          this.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES,
        )
        const newDeliveryNotes = response.data
        await this.$router.replace({ name: 'viewDeliveryNotes', params: { id: newDeliveryNotes.id } })
      } catch (error) {
        console.error(error)
        this.$toast.error('Ocurrió un error al crear el albaran. Por favor inténtelo de nuevo mas tarde.')
      }
      this.loading = false
    },
  },
}
</script>

<style scoped>
</style>
